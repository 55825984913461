<template>
  <div class="container">
    <div class="top_box">
      <div class="nei_box">
        <div class="img_box">
          <!--<img src="../../../assets/img/home/zhan_wei.png" />-->
          <img :src="gongsi.xq.img | ImgFilter" />
        </div>
        <div class="title">{{ gongsi.xq.company_name }}</div>
      </div>
    </div>
    <div class="bottom_box">
      <el-divider content-position="left">公司名称</el-divider>
      <div class="text_content">
        {{ gongsi.xq.company_name }}
      </div>
      <el-divider content-position="left">公司简介</el-divider>
      <div class="text_content">
        <!-- {{gongsi.list.description}} -->
      </div>

      <el-divider content-position="left">招聘职位</el-divider>
      <div class="zhi_wei_flex">
        <div class="flex_nei">
          <div
            class="one_box"
            v-for="(item, index) in gongsi.pin.data.slice(
              (this.pageNum - 1) * this.pageSize,
              this.pageNum * this.pageSize
            )"
            :key="index"
            @click="ming_cheng(item.id)"
          >
            <div class="top_class">
              <div class="gang_wei">{{ item.job_name }}</div>
              <div class="xin_zi">{{ item.salary }}k</div>
            </div>
            <div class="top_box_two">
              <span>{{ item.name_ren }}</span>
              <el-divider direction="vertical"></el-divider>
              <span v-if="item.education != null">{{
                item.education.name
              }}</span>
              <el-divider direction="vertical"></el-divider>
              <span v-if="item.degree != null">{{ item.degree.name }}</span>
            </div>
          </div>
        </div>
        <div class="ye_shu_two">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, prev, pager, next"
            :current-page.sync="pageNum"
            :page-size="pageSize"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { recruit_xqs, employer_list, rens } from "../../common/js/api";
export default {
  name: "Unitdetails",
  data() {
    return {
      total: 0,
      pageSize: 6,
      pageNum: 1,
      detail: {},
      bottom: [],
      renss: [],
      gongsi: {},
    };
  },
  created() {
    // this.zhao_detail()
    this.ren_shu();
    this.gong_si();
  },
  filters: {
    ImgFilter(value) {
      return "http://hout.kehui.cloud/" + value;
    },
  },
  methods: {
    ming_cheng(ids) {
      this.$router.push({
        path: "/positiondetail",
        query: {
          id: ids,
        },
      });
    },
    //上一页下一页按钮
    currentChange(val) {
      this.pageNum = val;
      // this.search_input()
    },
    //每页显示几条数据按钮
    sizeChange(val) {
      this.pageSize = val;
      // this.search_input()
    },
    gong_si() {
      recruit_xqs({
        id: this.$route.query.id,
      })
        .then((res) => {
          console.log(res.data);
          this.bottom = res.data.pin.data;
          this.total = res.data.pin.total;
          this.gongsi = res.data;
          for (var i = 0; i < this.bottom.length; i++) {
            var big = this.bottom[i];
            var big_num = this.bottom[i].p_number;
            for (var j = 0; j < this.renss.length; j++) {
              var samll_num = this.renss[j].id;
              if (big_num == samll_num) {
                Object.assign(big, { name_ren: this.renss[j].name });
              }
            }
          }
        })
        .catch((err) => {});
    },
    ren_shu() {
      rens({})
        .then((res) => {
          this.renss = res.data;
        })
        .catch((err) => {});
    },
    zhao_detail() {
      employer_list({
        id: this.$route.query.id,
        page: 1,
        limits: 6,
      })
        .then((res) => {
          console.log(res.data);
          this.bottom = res.data.pin.data;
          this.detail = res.data.list;

          for (var i = 0; i < this.bottom.length; i++) {
            var big = this.bottom[i];
            var big_num = this.bottom[i].p_number;
            for (var j = 0; j < this.renss.length; j++) {
              var samll_num = this.renss[j].id;
              if (big_num == samll_num) {
                Object.assign(big, { name_ren: this.renss[j].name });
              }
            }
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="less">
/deep/.el-divider--horizontal {
  margin-left: 5px;
  margin-top: 60px;
  width: 98%;
  border-top: 1px dashed rgba(56, 146, 235, 100);
}
/deep/.el-divider {
  /*background-color: unset;*/
  padding-right: -30px;
}
/deep/.el-divider__text {
  color: rgba(56, 146, 235, 100);
  font-size: 20px;
}
/deep/.el-divider__text.is-left {
  left: 0px;
}
.container {
  font-size: 14px;
  background-color: #f8f9fa;
  width: 100%;
  height: auto;
  overflow: hidden;
  .top_box {
    margin-top: 15px;
    width: 100%;
    height: 178px;
    line-height: 20px;
    background-color: rgba(235, 245, 254, 100);
    .nei_box {
      width: 1200px;
      margin: 0px auto;
      display: flex;
      align-items: center;
      .img_box {
        margin-top: 50px;
        width: 100px;
        height: 100px;
        img {
          width: 100px;
          height: 100px;
        }
      }
      .title {
        margin-top: 50px;
        margin-left: 30px;
        color: rgba(16, 16, 16, 100);
        font-size: 18px;
      }
    }
  }
  .bottom_box {
    background-color: #fff;
    margin: 30px auto;
    width: 1200px;
    height: 686px;
    line-height: 20px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 100);
    .text_content {
      margin-left: 28px;
      color: rgba(105, 105, 105, 100);
      text-align: left;
      font-size: 16px;
    }
    .zhi_wei_flex {
      .flex_nei {
        display: flex;
        flex-wrap: wrap;
        .one_box {
            cursor: pointer;
          margin-left: 28px;
          margin-top: 30px;
          width: 263px;
          height: 100px;
          line-height: 20px;
          background-color: rgba(255, 255, 255, 100);
          text-align: center;
          border: 1px solid rgba(187, 187, 187, 100);
          .top_class {
            display: flex;
            justify-content: space-between;
            margin: 15px;
            .gang_wei {
              color: rgba(16, 16, 16, 100);
              font-size: 18px;
            }
            .xin_zi {
              color: rgba(255, 0, 0, 100);
              font-size: 18px;
            }
          }
          .top_box_two {
            margin: 15px;
            text-align: left;
          }
        }
      }
      .ye_shu_two {
        margin-top: 30px;
      }
    }
  }
}
</style>