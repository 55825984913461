<template>
    <div>
        <con-tab ></con-tab>
        <!--轮播图-->
        <need-nei ></need-nei>
    </div>
</template>

<script>
    import ConTab from "../../components/common/ConTab";
    import NeedNei from "./base/Unitdetails";
    export default {
        components:{
            ConTab,
            NeedNei,
        }
    }
</script>

<style scoped>

</style>